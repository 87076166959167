
import React from "react";

function getDateAndTime() { 
    const now = new Date();
    let day = now.getDate();
    let month = now.getMonth() + 1;
    const year = now.getUTCFullYear();
    if (day < 10) { day = '0' + day; }
    if (month < 10) { month = '0' + month; }
    let hour = now.getHours();
    let minutes = now.getMinutes();
    if (hour < 10) { hour = '0' + hour }
    if (minutes < 10) { minutes = '0' + minutes }
    return day + '.' + month + '.' + year + ' ' + hour + ':' + minutes;
}

export default function Navbar({user}) {
    return  <nav className="navbar bg-body-tertiary">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-info">{getDateAndTime()}<br />{user.vorname} {user.name} ({ user.firma })</div>
                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Zeiterfassung</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li className="nav-item">
                                <a className="nav-link bi bi-clock" href="/">&nbsp; Erfassung</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link bi bi-journal" href="/projekte">&nbsp; Projekte</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link bi bi-people" href="/anwesende">&nbsp; Mitarbeiter</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link bi bi-box-arrow-right" href="/login">&nbsp; Abmelden</a>
                            </li>
                            </ul>
                        </div>
                        <div className="offcanvas-footer">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item">
                                    <a className="nav-link bi bi-info-circle" href="/info">&nbsp; Info</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
}

